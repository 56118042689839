import { useState } from 'react';

const SustainabilityData = () => {
  return (
    <>
      <p>
        Sustainability data used in Portfolio Dragon system are provided by ESG Book, these data include: ESG, GC,
        temperature scores and preference filters. We provide some descriptions for each of the aforementioned data in
        the following sections.
      </p>
      <p>
        <a href="https://www.esgbook.com/" target="_blank" rel="noreferrer">
          Refer to ESG Book Website for further details.
        </a>
      </p>
      <ul>
        <li>
          <p>
            <b>The ESG score</b>, ranging from 0 to 100, identifies sustainable companies that are better positioned to
            outperform over the long run, based on the principles of financial materiality. That is, when computing the
            ESG score of a company, the algorithm will only use information that significantly helps explain future
            risk-adjusted performance. Materiality is applied by overweighting features with higher materiality and
            rebalancing these weights on a rolling quarterly basis to stay up-to-date. The ESG consists of three
            sub-scores:
          </p>
          <ul>
            <li>
              Environment: Emissions, environmental stewardship, resource use, environmental solutions, Waste, water,
              environmental management
            </li>
            <li>
              Social: Diversity, occupational health and safety, training and development, product access, community
              relations, product quality and safety, human rights, labour rights, compensation, employment quality
            </li>
            <li>
              Governance: Business ethics, corporate governance, transparency, forensic accounting, capital structure
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>The GC score</b>, ranges from 0 to 100, is a normative assessment of each company based on the four core
            principles of the United Nations Global Compact - UNGC: Human rights, Labour rights, Environment and
            Anti-corruption. With more than 9,000 corporate signatories from over 160 countries, the Global Compact is
            the world’s largest corporate sustainability initiative, calling on companies and stakeholders to conduct
            business responsibly, and to pursue opportunities that advance Sustainable Development Goals (SDGs). Each
            core principle focuses on the following issues:
          </p>
          <ul>
            <li>
              <b>Human rights</b>: Labour rights, occupational health and safety, employment quality, diversity, product
              quality and safety, product access, community relations
            </li>
            <li>
              <b>Labour rights</b>: Diversity, compensation, occupational health and safety, training and development,
              labour rights, employment quality
            </li>
            <li>
              <b>Environment</b>: Emissions, waste, environmental stewardship, environmental management, resource use,
              environment water, environmental solutions.
            </li>
            <li>
              <b>Anti-corruption</b>: Anti-corruption business ethics, corporate governance, transparency
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Preferences Filter</b>: is a bespoke search tool that examines the business involvements of companies
            against their personal values. The tool allows the user to look into whether companies are engaging in
            certain activities, enabling the user to avoid those that do not align with personal preferences. The
            Preferences Filter examines revenue streams, and will flag a company if it derives significant revenues from
            a particular activity. There are two kinds of filter:
          </p>
          <ul>
            <li>
              <p>
                <b>Standard Filter</b>:
              </p>
              <ul>
                <li>
                  Adult entertainment: Production and distribution of adult content online or in television, video or
                  magazines.
                </li>
                <li>Alcohol: Manufacture, production and wholesale of alcoholic beverages.</li>
                <li>Thermal coal: Mining and processing of thermal coal, or the leasing of thermal coal mines.</li>
                <li>
                  Weapons Involvement: Involvement in the manufacturing and supply-chain of weapons, in the
                  manufacturing and supply-chain of landmines, cluster bombs, chemical and biological weapons, or
                  nuclear.
                </li>
                <li>
                  Defence: Advanced combat equipment, services and technology designed for the defence industry,
                  including communications, electronics and software designed for defence applications.
                </li>
                <li>Drug: Cultivation of cannabis and production of cannabis products.</li>
                <li>Firearm: Weaponry, small firearms, and ammunition.</li>
                <li>
                  Fossil_fuel: Oil, gas and coal exploration, production and distribution. Oil and gas equipment and
                  services. Provision of electricity generated from fossil fuels.
                </li>
                <li>Gambling: Casinos, casino hotels, gambling equipment and software, and horse racing industries.</li>
                <li>GMO: Plant and animal breeding, genetics and genomics.</li>
                <li>
                  Nuclear: Mining of uranium/radium/vanadium, processing nuclear waste, provision of electricity
                  generated through nuclear power.
                </li>
                <li>Stem Cell: Genomic screening and cloning, collecting and storing stem cells.</li>
                <li>Tobacco: Manufacture, production and wholesale of tobacco and tobacco products.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <b>Temperature scores</b>: quantifies the extent to which corporations are contributing to the rise in global
          temperature through their greenhouse gas emissions. The score is inspired by the Paris Agreement which saw 197
          countries agree that global temperature rise needs to be kept “well below 2C” and to “pursue efforts to limit
          the temperature increase to 1.5°C”. Companies are given a near-term (by the year 2030) and long-term (by the
          year 2050) score among 1.5°C, 2°C, 2.7°C, &gt;2.7°C to represent their climate pathways. 3°C This score is
          given to companies that have incomplete emissions reporting.
        </li>
      </ul>
    </>
  );
};

const PortfolioConfiguration = () => {
  return (
    <>
      <p>
        Using <b>ESG </b> and <b>GC </b> scores, the investment universe can be configured according to a sustainability
        target by removing a percentage of companies with the <b>lowest </b>
        sustainability score from the investible universe.
      </p>
      <p>
        For ESG, there are four filters which target the ESG total score and its sub-scores: Environment, Social and
        Governance.
      </p>
      <p>
        For UNGC, there are five filters which target the total UNGC score and its four sub-scores: Human rights, Labour
        Rights, Environment and Anti-corruption.
      </p>
      <p>
        The filters are used in conjunction. For example, a selection of 40% for ESG total score, 10% for E and 20% for
        S will construct an universe consists of assets which simultaneous belong in the top 60%, 90% and 80% ranking
        for ESG, E and S score respectively.
      </p>
    </>
  );
};

const TemperatureScore = () => {
  return (
    <>
      <p>Temperature score filters provide four options</p>
      <ul>
        <li>
          <b>1.5°C:</b> exclude all stocks whose activities will lead to greater than 1.5°C: warming by 2050 according
          to ESG Book
        </li>
        <li>
          <b>2°C:</b> exclude all stocks whose activities will lead to greater than 2.°C: warming by 2050 according to
          ESG Book
        </li>
        <li>
          <b>2.7°C:</b> exclude all stocks whose activities will lead to greater than 2.7°C: warming by 2050 according
          to ESG Book
        </li>
        <li>
          <b>None:</b> no exclusion based on projected contribution to warming.
        </li>
      </ul>
    </>
  );
};

const PreferenceFilters = () => {
  return (
    <>
      <p>
        Standard preference filters. Each filter allows the exclusion of stocks according to their involvement with
        certain business segments. The filters are enumerated below:
      </p>
      <ul>
        <li>
          <b>Adult entertainment:</b> Production and distribution of adult content online or in television, video or
          magazines.
        </li>
        <li>
          <b>Alcohol:</b> Manufacture, production and wholesale of alcoholic beverages.
        </li>
        <li>
          <b>Defence:</b> Advanced combat equipment, services and technology designed for the defence industry,
          including communications, electronics and software designed for defence applications.
        </li>
        <li>
          <b>Drugs:</b> Cultivation of cannabis and production of cannabis products.
        </li>
        <li>
          <b>Firearms:</b> Weaponry, small firearms, and ammunition.
        </li>
        <li>
          <b>Fossil fuels:</b> Oil, gas and coal exploration, production and distribution. Oil and gas equipment and
          services. Provision of electricity generated from fossil fuels.
        </li>
        <li>
          <b>Gambling:</b> Casinos, casino hotels, gambling equipment and software, and horse racing industries.
        </li>
        <li>
          <b>GMO:</b> Plant and animal breeding, genetics and genomics.
        </li>
        <li>
          <b>Nuclear:</b> Mining of uranium/radium/vanadium, processing nuclear waste, provision of electricity
          generated through nuclear power.
        </li>
        <li>
          <b>Thermal coal:</b> Mining and processing of thermal coal, or the leasing of thermal coal mines.
        </li>
        <li>
          <b>Tobacco:</b> Manufacture, production and wholesale of tobacco and tobacco products.
        </li>
        <li>
          <b>Weapons Involvement:</b> Involvement in the manufacturing and supply-chain of weapons
        </li>
        <li>
          <b>Controversial weapons:</b> Involvement in the manufacturing and supply-chain of landmines, cluster bombs,
          chemical and biological weapons, or nuclear.
        </li>
        <li>
          <b>Stem cells:</b> Genomic screening and cloning, collecting and storing stem cells.
        </li>
      </ul>
    </>
  );
};

const Tilt = () => {
  return (
    <>
      <p>
        Tilt configuration allow users to configure a strategy's stock selection behaviour with the following options:
      </p>
      <ol>
        <li>
          <b>Any</b>
          <ul>
            <li>No tilt towards any particular factor has been specified.</li>
          </ul>
        </li>
        <li>
          <b>Growth</b>
          <ul>
            <li>Tilt the portfolio to having exposure to the growth factor, proxied using 1 year earnings growth.</li>
          </ul>
        </li>
        <li>
          <b>Low volatility</b>
          <ul>
            <li>
              Tilt the portfolio to having exposure to the low volatility factor, proxied using 1 year daily volatility.
            </li>
          </ul>
        </li>
        <li>
          <b>Momentum</b>
          <ul>
            <li>
              Tilt the portfolio to having exposure to the momentum factor, proxied using the difference between the 12
              month return and 1 month return.
            </li>
          </ul>
        </li>
        <li>
          <b>Value</b>
          <ul>
            <li>Tilt the portfolio to having exposure to the value factor, proxied using the price-to-book ratio.</li>
          </ul>
        </li>
      </ol>
    </>
  );
};

const TiltImplementation = () => {
  return (
    <>
      <p>
        Tilt implementation configuration allows user to control how Tilt behaviour is implemented. Currently, there are
        two methods provided:
      </p>
      <ol>
        <li>
          <b>Constraint</b>
          <ul>
            <li>Constrain the optimization to select a portfolio whose average exposure is 0.5 (Z-score).</li>
          </ul>
        </li>
        <li>
          <b>Filter</b>
          <ul>
            <li>Filter the investible universe according to the exposure of each stock to a factor.</li>
          </ul>
        </li>
      </ol>
    </>
  );
};

const faqItems = [
  {
    question: 'What is sustainability data i.e. ESG, GC and temperature scores used by Portfolio Dragon?',
    answer: <SustainabilityData />,
  },
  {
    question: 'How does portfolio configuration using ESG sustainability data work?',
    answer: <PortfolioConfiguration />,
  },
  {
    question: 'How does portfolio configuration using temperature score work?',
    answer: <TemperatureScore />,
  },
  {
    question: 'How does portfolio configuration using preference filters work?',
    answer: <PreferenceFilters />,
  },
  {
    question: 'How does portfolio configuration using tilt work?',
    answer: <Tilt />,
  },
  {
    question: 'How does portfolio configuration using tilt implementation work?',
    answer: <TiltImplementation />,
  },
];

export default function Faqs() {
  const [expandedIndex, setExpandedIndex] = useState<number | null>();

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="mx-auto mb-20 w-full max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqItems.map((item, index) => (
            <div className="pt-6" key={index}>
              <dt>
                <button
                  type="button"
                  className="flex w-full items-start justify-between text-left text-gray-900"
                  aria-controls={`faq-${index}`}
                  aria-expanded={expandedIndex === index}
                  onClick={() => toggleExpand(index)}
                >
                  <span className="text-base font-semibold leading-7">{item.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <svg
                      className={`h-6 w-6 ${expandedIndex === index ? 'hidden' : 'block'}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    <svg
                      className={`h-6 w-6 ${expandedIndex === index ? 'block' : 'hidden'}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                    </svg>
                  </span>
                </button>
              </dt>
              {expandedIndex === index && (
                <dd className="mt-2 pr-12" id={`faq-${index}`}>
                  <div className="text-gray-600 [&_li]:mb-5 [&_p]:mb-5">{item.answer}</div>
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
